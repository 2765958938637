import { ApiService, ApiError } from "./api.service";
const EmailService = {
  async getCodeFromEmail(payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Email`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },
};

export default EmailService;
