import { ApiService, ApiError } from "./api.service";

const OrderService = {
  async getOrder(id) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Order?id=${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async deleteOrder(id) {
    try {
      const response = await ApiService.delete(`${ApiService.baseAddress}Order/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async updateOrder(payload) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}Order`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getOrdersByEmail(email) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Order/GetOrdersByEmail/${email}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async findOrder(usernameOrEmail) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Order/Find`, usernameOrEmail);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getAccountsBySoldProductId(id) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Order/GetAccountsBySoldProductId/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async renewCompensate(id) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Order/RenewCompensate/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async refundProduct(id) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Order/RefundProduct`, id);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async refundOrder(id) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Order/RefundOrder`, id);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async createOrder(order) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Order`, order);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async deliverOrder(order) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Order/Deliver`, order);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async checkAccounts(id) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Order/SoldProduct/Check/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async createInvoice(invoice) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Order/CreateInvoice`, invoice);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },
};

export default OrderService;
