import { createStore } from "vuex";
import * as auth from "./modules/auth";
import * as user from "./modules/user";
import * as product from "./modules/product";
import * as payment from "./modules/payment";
import * as notification from "./modules/notification";
import * as order from "./modules/order";
import * as statistics from "./modules/statistics";
import * as workers from "./modules/workers";
import * as email from "./modules/email";

function getLocalTheme() {
  try {
    if (JSON.parse(localStorage.getItem("theme"))) {
      if (JSON.parse(localStorage.getItem("theme")) == "light" || JSON.parse(localStorage.getItem("theme")) == "dark") {
        return JSON.parse(localStorage.getItem("theme"));
      } else return "light";
    } else return "light";
  } catch (error) {
    return "light";
  }
}

function getLocalSidebar() {
  try {
    if (JSON.parse(localStorage.getItem("sidebarCollapsed"))) {
      if (JSON.parse(localStorage.getItem("sidebarCollapsed")) === true) return true;
      else return false;
    } else return false;
  } catch (error) {
    return false;
  }
}

export default createStore({
  state: {
    theme: getLocalTheme(),
    sidebarCollapsed: getLocalSidebar(),
    toasts: [],
    domain: "hesapal",
  },
  getters: {
    getToasts: (state) => {
      return state.toasts;
    },
    getTheme: (state) => {
      return state.theme;
    },
    getSidebarCollapsed: (state) => {
      return state.sidebarCollapsed;
    },
    getDomain: (state) => {
      return state.domain == "hesapal" ? "hesapal.org" : "twaccs.com";
    },
    getApiAddress: (state) => {
      return state.domain == "hesapal" ? "https://prod-hesapal-admin-api-sjl2lbetbq-ew.a.run.app/" : "https://prod-twaccs-admin-api-sjl2lbetbq-ew.a.run.app/";
    },
  },
  mutations: {
    addToast(state, toast) {
      state.toasts.push(toast);
    },
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem("theme", JSON.stringify(theme));
    },
    setSidebarCollapsed(state, isCollapsed) {
      state.sidebarCollapsed = isCollapsed;
      localStorage.setItem("sidebarCollapsed", isCollapsed);
    },
    setDomain(state, domain) {
      state.domain = domain;
    },
  },
  actions: {
    addToast({ commit }, toast) {
      if (!toast.message) toast.message = "Bağlantı Hatası.";
      commit("addToast", toast);
    },
    setDomain({ commit }, domain) {
      commit("setDomain", domain);
    },
  },
  modules: {
    auth,
    user,
    product,
    payment,
    notification,
    order,
    statistics,
    workers,
    email,
  },
});
