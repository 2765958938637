import { ApiService, ApiError } from "./api.service";

const PaymentService = {
  async getPayments() {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Payment`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getRefunds() {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Refund`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async updateRefund(refund) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}Refund`, refund);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async deleteRefund(id) {
    try {
      const response = await ApiService.delete(`${ApiService.baseAddress}Refund/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },
};

export default PaymentService;
