import { ApiService, ApiError } from "./api.service";

const StatisticsService = {
  async getIncomeStatement(payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Statistics/GetIncomeStatement`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getProductAnalysis(payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Statistics/GetProductAnalysis`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getTopShoppersUsers(payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Statistics/GetTopShoppersUsers`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getUserCompensationAnalysis(payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Statistics/GetUserCompensationAnalysis`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getStockNotificationCount(payload) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Statistics/GetStockNotificationCount`, payload);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },
};

export default StatisticsService;
