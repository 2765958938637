import StatisticsService from "../../services/statistics.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getIncomeStatement({ dispatch }, payload) {
    try {
      const response = await StatisticsService.getIncomeStatement(payload);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getProductAnalysis({ dispatch }, payload) {
    try {
      const response = await StatisticsService.getProductAnalysis(payload);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getTopShoppersUsers({ dispatch }, payload) {
    try {
      const response = await StatisticsService.getTopShoppersUsers(payload);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getUserCompensationAnalysis({ dispatch }, payload) {
    try {
      const response = await StatisticsService.getUserCompensationAnalysis(payload);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getStockNotificationCount({ dispatch }, payload) {
    try {
      const response = await StatisticsService.getStockNotificationCount(payload);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },
};
