import { ApiService, ApiError } from "./api.service";
import StorageService from "./storage.service";
import store from "../store";

const AuthService = {
  signIn: async function (credentials) {
    try {
      const response = await ApiService.post(ApiService.baseAddress + "Auth/Login", credentials);
      StorageService.saveUser(response.data.data.user, store.state.domain);
      StorageService.saveToken(response.data.data.token, store.state.domain);
      ApiService.setHeader();
      ApiService.mount401Interceptor();
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  signOut() {
    StorageService.removeUser(store.state.domain);
    StorageService.removeToken(store.state.domain);
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
  },
};

export default AuthService;
