<template>
  <div class="custom-toast animate-pop" :class="toast.type" :id="`custom-toast-${index}`" @click="removeToast">
    <i :class="toastIcon"></i>
    <span>{{ toast.message }}</span>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: {
    toast: { required: true, type: Object },
    index: { required: true, type: Number },
  },
  data() {
    return {
      durationTimeout: null,
    };
  },
  computed: {
    toastIcon() {
      switch (this.toast.type) {
        case "error":
          return "bx bxs-x-circle";
        case "success":
          return "bx bxs-check-circle";
        case "warning":
          return "bx bxs-error-circle";
        case "info":
          return "bx bxs-info-circle";
        default:
          return "bx bxs-info-circle";
      }
    },
  },
  created() {
    this.durationTimeout = setTimeout(() => {
      this.removeToast();
    }, 5000);
  },
  methods: {
    removeToast() {
      clearTimeout(this.durationTimeout);
      let toast = document.getElementById(`custom-toast-${this.index}`);
      toast.classList.remove("animate-pop");
      toast.classList.add("fade-out");
      setTimeout(() => {
        toast.remove();
      }, 200);
    },
  },
};
</script>

<style scoped>
.custom-toast.success {
  background: #52be80;
}

.custom-toast.error {
  background: #d52a55;
}

.custom-toast.info {
  background: #6ab4e0;
}

.custom-toast.warning {
  background: #ff6f41;
}

.custom-toast {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  max-width: 30rem;
  transition: opacity 0.2s ease;
}

.custom-toast i {
  font-size: 1.3rem;
  margin-right: 8px;
}

.custom-toast span {
  font-size: 0.9rem;
  line-height: 1.1;
  font-weight: 500;
}

.fade-out {
  opacity: 0;
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.animate-pop {
  animation-duration: 0.2s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}
</style>
