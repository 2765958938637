import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import { ApiService } from "../services/api.service";
import StorageService from "../services/storage.service";

const routes = [
  {
    path: "/",
    redirect: `${store.state.domain}`,
    children: [
      {
        path: "/:domain(twaccs|hesapal)",
        component: () => import("@/layouts/DashboardLayout.vue"),
        children: [
          {
            path: "",
            name: "Dashboard",
            component: () => import("@/views/Dashboard/Dashboard.vue"),
          },
          {
            path: "urunler",
            name: "Products",
            component: () => import("@/views/Dashboard/Products.vue"),
          },
          {
            path: "siparisler",
            name: "Orders",
            component: () => import("@/views/Dashboard/Orders.vue"),
          },
          {
            path: "kullanicilar",
            name: "Users",
            component: () => import("@/views/Dashboard/Users.vue"),
          },
          {
            path: "odemeler",
            name: "Payments",
            component: () => import("@/views/Dashboard/Payments.vue"),
          },
          {
            path: "bildirimler",
            name: "Notifications",
            component: () => import("@/views/Dashboard/Notifications.vue"),
          },
          {
            path: "istatistik",
            name: "Statistics",
            component: () => import("@/views/Dashboard/Statistics.vue"),
          },
          {
            path: "genel-bakis",
            name: "Overview",
            component: () => import("@/views/Dashboard/Overview.vue"),
          },
        ],
      },
      {
        path: "/:domain(twaccs|hesapal)",
        redirect: `/giris-yap`,
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
        },
        component: () => import("@/layouts/AuthLayout.vue"),
        children: [
          {
            path: "giris-yap",
            name: "SignIn",
            component: () => import("@/views/Auth/SignIn.vue"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  to.params.domain != undefined ? store.dispatch("setDomain", to.params.domain) : "";
  ApiService.setApiAdress(store.getters.getApiAddress);

  if (!ApiService.getHeader()) {
    if (StorageService.getToken("hesapal") || StorageService.getToken("twaccs")) {
      ApiService.setHeader();
      ApiService.mount401Interceptor();
    }
  }

  if (store.state.auth.token == "") store.dispatch("auth/setToken", store.state.domain);
  if (store.state.auth.user == "") store.dispatch("auth/setUser", store.state.domain);

  let signedIn = store.getters["auth/signedIn"];
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);

  if (!isPublic && !signedIn) {
    next({
      path: `${store.state.domain}/giris-yap`,
      query: { redirect: to.fullPath },
    });
  } else if (signedIn && onlyWhenLoggedOut) {
    next(`/${store.state.domain}`);
  } else {
    next();
  }
});

export default router;
