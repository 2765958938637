import EmailService from "../../services/email.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getCodeFromEmail({ dispatch }, payload) {
    try {
      const response = await EmailService.getCodeFromEmail(payload);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },
};
