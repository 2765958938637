import NotificationService from "../../services/notification.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getNotifications({ dispatch }) {
    try {
      const response = await NotificationService.getNotifications();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getNotification({ dispatch }, id) {
    try {
      const response = await NotificationService.getNotification(id);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async addNotification({ dispatch }, notification) {
    try {
      const response = await NotificationService.addNotification(notification);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Bildirim başarıyla eklendi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async updateNotification({ dispatch }, notification) {
    try {
      const response = await NotificationService.updateNotification(notification);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Bildirim başarıyla güncellendi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async deleteNotification({ dispatch }, id) {
    try {
      const response = await NotificationService.deleteNotification(id);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Bildirim başarıyla silindi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getBanners({ dispatch }) {
    try {
      const response = await NotificationService.getBanners();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async addBanner({ dispatch }, banner) {
    try {
      const response = await NotificationService.addBanner(banner);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Banner başarıyla eklendi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async updateBanner({ dispatch }, banner) {
    try {
      const response = await NotificationService.updateBanner(banner);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Banner başarıyla güncellendi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async deleteBanner({ dispatch }, id) {
    try {
      const response = await NotificationService.deleteBanner(id);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Banner başarıyla silindi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },
};
