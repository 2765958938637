import { ApiService, ApiError } from "./api.service";

const ProductService = {
  async getProducts() {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Product`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getAllProducts() {
    try {
      const hesapalProducts = await ApiService.get(`https://prod-hesapal-admin-api-sjl2lbetbq-ew.a.run.app/Product`);
      const twaccsProducts = await ApiService.get(`https://prod-twaccs-admin-api-sjl2lbetbq-ew.a.run.app/Product`);
      return {
        hesapalProducts: hesapalProducts.data.data.products,
        twaccsProducts: twaccsProducts.data.data.products,
      };
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async updateProduct(id, isAvailable) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}Product`, id, isAvailable);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async exportAccount(productId, count) {
    try {
      const response = await ApiService.delete(`${ApiService.baseAddress}Product/DeleteAccount`, productId, count);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getSample(url) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Product/${url}/Sample`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },
};

export default ProductService;
