import UserService from "../../services/user.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getUsers({ dispatch }) {
    try {
      const response = await UserService.getUsers();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },
};
