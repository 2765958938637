const USER = "user";
const TOKEN = "token";

const StorageService = {
  getUser(domain) {
    if (JSON.parse(localStorage.getItem(USER + domain))) return JSON.parse(localStorage.getItem(USER + domain));
    else return null;
  },
  getToken(domain) {
    if (JSON.parse(localStorage.getItem(TOKEN + domain))) return JSON.parse(localStorage.getItem(TOKEN + domain));
    else return null;
  },
  saveUser(user, domain) {
    localStorage.setItem(USER + domain, JSON.stringify(user));
  },
  saveToken(token, domain) {
    localStorage.setItem(TOKEN + domain, JSON.stringify(token));
  },
  removeUser(domain) {
    localStorage.removeItem(USER + domain);
  },
  removeToken(domain) {
    localStorage.removeItem(TOKEN + domain);
  },
};

export default StorageService;
