import WorkersService from "../../services/workers.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getBotEfficiencyRate({ dispatch }, { domain, time }) {
    try {
      const response = await WorkersService.getBotEfficiencyRate(domain, time);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getBotEfficiencyRateGraph({ dispatch }, { domain, timeRange }) {
    try {
      const response = await WorkersService.getBotEfficiencyRateGraph(domain, timeRange);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getTotalBots({ dispatch }, { domain, time }) {
    try {
      const response = await WorkersService.getTotalBots(domain, time);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getTotalBotsGraph({ dispatch }, { domain, timeRange }) {
    try {
      const response = await WorkersService.getTotalBotsGraph(domain, timeRange);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getActiveBots({ dispatch }, { domain, time }) {
    try {
      const response = await WorkersService.getActiveBots(domain, time);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getActiveBotsGraph({ dispatch }, { domain, timeRange }) {
    try {
      const response = await WorkersService.getActiveBotsGraph(domain, timeRange);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getBannedBots({ dispatch }, { domain, time }) {
    try {
      const response = await WorkersService.getBannedBots(domain, time);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getBannedBotsGraph({ dispatch }, { domain, timeRange }) {
    try {
      const response = await WorkersService.getBannedBotsGraph(domain, timeRange);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getSheepEfficiencyRate({ dispatch }, { domain, time }) {
    try {
      const response = await WorkersService.getSheepEfficiencyRate(domain, time);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getSheepEfficiencyRateGraph({ dispatch }, { domain, timeRange }) {
    try {
      const response = await WorkersService.getSheepEfficiencyRateGraph(domain, timeRange);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getTotalSheeps({ dispatch }, { domain, time }) {
    try {
      const response = await WorkersService.getTotalSheeps(domain, time);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getTotalSheepsGraph({ dispatch }, { domain, timeRange }) {
    try {
      const response = await WorkersService.getTotalSheepsGraph(domain, timeRange);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getActiveSheeps({ dispatch }, { domain, time }) {
    try {
      const response = await WorkersService.getActiveSheeps(domain, time);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getActiveSheepsGraph({ dispatch }, { domain, timeRange }) {
    try {
      const response = await WorkersService.getActiveSheepsGraph(domain, timeRange);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getBannedSheeps({ dispatch }, { domain, time }) {
    try {
      const response = await WorkersService.getBannedSheeps(domain, time);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getBannedSheepsGraph({ dispatch }, { domain, timeRange }) {
    try {
      const response = await WorkersService.getBannedSheepsGraph(domain, timeRange);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },
};
