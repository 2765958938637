<template>
  <div class="custom-toast-wrapper">
    <Toast v-for="(toast, index) of toasts" :key="index" :toast="toast" :index="index"> </Toast>
  </div>
</template>

<script>
import Toast from "./Toast.vue";
export default {
  name: "Toasts",
  components: { Toast },
  computed: {
    toasts() {
      return this.$store.state.toasts;
    },
  },
};
</script>

<style scoped>
.custom-toast-wrapper {
  max-height: 90vh;
  height: fit-content;
  position: fixed;
  width: fit-content;
  margin: 1rem auto;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10001;
}

@media (min-width: 768px) {
  .custom-toast-wrapper {
    top: 0;
    right: 0;
    margin-top: 2rem;
    margin-right: 2rem;
  }
}
</style>
