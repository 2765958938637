import ProductService from "../../services/product.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getProducts({ dispatch }) {
    try {
      const response = await ProductService.getProducts();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getAllProducts({ dispatch }) {
    try {
      const response = await ProductService.getAllProducts();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async updateProduct({ dispatch }, id, isAvailable) {
    try {
      const response = await ProductService.updateProduct(id, isAvailable);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Ürün başarıyla güncellendi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async exportAccount({ dispatch }, productId, count) {
    try {
      const response = await ProductService.exportAccount(productId, count);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getSample({ dispatch }, url) {
    try {
      const response = await ProductService.getSample(url);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },
};
