import { ApiService, ApiError } from "./api.service";

const UserService = {
  async getUsers() {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}User`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },
};

export default UserService;
