import { ApiService, ApiError } from "./api.service";

const NotificationService = {
  async getNotifications() {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Notification`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getNotification(id) {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Notification/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async addNotification(notification) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Notification`, notification);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async updateNotification(notification) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}Notification`, notification);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async deleteNotification(id) {
    try {
      const response = await ApiService.delete(`${ApiService.baseAddress}Notification/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getBanners() {
    try {
      const response = await ApiService.get(`${ApiService.baseAddress}Banner`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async addBanner(banner) {
    try {
      const response = await ApiService.post(`${ApiService.baseAddress}Banner`, banner);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async updateBanner(banner) {
    try {
      const response = await ApiService.put(`${ApiService.baseAddress}Banner`, banner);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async deleteBanner(id) {
    try {
      const response = await ApiService.delete(`${ApiService.baseAddress}Banner/${id}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },
};

export default NotificationService;
