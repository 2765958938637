import PaymentService from "../../services/payment.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getPayments({ dispatch }) {
    try {
      const response = await PaymentService.getPayments();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getRefunds({ dispatch }) {
    try {
      const response = PaymentService.getRefunds();
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async updateRefund({ dispatch }, refund) {
    try {
      const response = PaymentService.updateRefund(refund);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "İade durumu başarıyla güncellendi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async deleteRefund({ dispatch }, id) {
    try {
      const response = PaymentService.deleteRefund(id);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "İade başarıyla silindi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },
};
