import { ApiService, ApiError } from "./api.service";
const WorkersService = {
  async getBotEfficiencyRate(domain, time) {
    try {
      const response = await ApiService.get(`http://45.143.99.64:9090/api/v1/query?query=twitter_efficiency_percent_bots{job="twitter-creator-${domain}"}&time=${time}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getBotEfficiencyRateGraph(domain, timeRange) {
    try {
      const response = await ApiService.get(
        `http://45.143.99.64:9090/api/v1/query_range?query=twitter_efficiency_percent_bots{job="twitter-creator-${domain}"}&start=${timeRange.start}&end=${timeRange.end}&step=365`
      );
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getTotalBots(domain, time) {
    try {
      const response = await ApiService.get(`http://45.143.99.64:9090/api/v1/query?query=twitter_efficiency_total_bots{job="twitter-creator-${domain}"}&time=${time}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getTotalBotsGraph(domain, timeRange) {
    try {
      const response = await ApiService.get(
        `http://45.143.99.64:9090/api/v1/query_range?query=twitter_efficiency_total_bots{job="twitter-creator-${domain}"}&start=${timeRange.start}&end=${timeRange.end}&step=365`
      );
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getActiveBots(domain, time) {
    try {
      const response = await ApiService.get(`http://45.143.99.64:9090/api/v1/query?query=twitter_efficiency_active_bots{job="twitter-creator-${domain}"}&time=${time}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getActiveBotsGraph(domain, timeRange) {
    try {
      const response = await ApiService.get(
        `http://45.143.99.64:9090/api/v1/query_range?query=twitter_efficiency_active_bots{job="twitter-creator-${domain}"}&start=${timeRange.start}&end=${timeRange.end}&step=365`
      );
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getBannedBots(domain, time) {
    try {
      const response = await ApiService.get(`http://45.143.99.64:9090/api/v1/query?query=twitter_efficiency_banned_bots{job="twitter-creator-${domain}"}&time=${time}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getBannedBotsGraph(domain, timeRange) {
    try {
      const response = await ApiService.get(
        `http://45.143.99.64:9090/api/v1/query_range?query=twitter_efficiency_banned_bots{job="twitter-creator-${domain}"}&start=${timeRange.start}&end=${timeRange.end}&step=365`
      );
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getSheepEfficiencyRate(domain, time) {
    try {
      const response = await ApiService.get(`http://45.143.99.64:9090/api/v1/query?query=twitter_efficiency_percent_sheeps{job="twitter-creator-${domain}"}&time=${time}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getSheepEfficiencyRateGraph(domain, timeRange) {
    try {
      const response = await ApiService.get(
        `http://45.143.99.64:9090/api/v1/query_range?query=twitter_efficiency_percent_sheeps{job="twitter-creator-${domain}"}&start=${timeRange.start}&end=${timeRange.end}&step=365`
      );
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getTotalSheeps(domain, time) {
    try {
      const response = await ApiService.get(`http://45.143.99.64:9090/api/v1/query?query=twitter_efficiency_total_sheeps{job="twitter-creator-${domain}"}&time=${time}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getTotalSheepsGraph(domain, timeRange) {
    try {
      const response = await ApiService.get(
        `http://45.143.99.64:9090/api/v1/query_range?query=twitter_efficiency_total_sheeps{job="twitter-creator-${domain}"}&start=${timeRange.start}&end=${timeRange.end}&step=365`
      );
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getActiveSheeps(domain, time) {
    try {
      const response = await ApiService.get(`http://45.143.99.64:9090/api/v1/query?query=twitter_efficiency_active_sheeps{job="twitter-creator-${domain}"}&time=${time}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getActiveSheepsGraph(domain, timeRange) {
    try {
      const response = await ApiService.get(
        `http://45.143.99.64:9090/api/v1/query_range?query=twitter_efficiency_active_sheeps{job="twitter-creator-${domain}"}&start=${timeRange.start}&end=${timeRange.end}&step=365`
      );
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getBannedSheeps(domain, time) {
    try {
      const response = await ApiService.get(`http://45.143.99.64:9090/api/v1/query?query=twitter_efficiency_banned_sheeps{job="twitter-creator-${domain}"}&time=${time}`);
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },

  async getBannedSheepsGraph(domain, timeRange) {
    try {
      const response = await ApiService.get(
        `http://45.143.99.64:9090/api/v1/query_range?query=twitter_efficiency_banned_sheeps{job="twitter-creator-${domain}"}&start=${timeRange.start}&end=${timeRange.end}&step=365`
      );
      return response.data;
    } catch (error) {
      throw new ApiError(error.response.data.code, error.response.data.message);
    }
  },
};

export default WorkersService;
