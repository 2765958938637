import AuthService from "../../services/auth.service";
import StorageService from "../../services/storage.service";
import router from "../../router";

export const namespaced = true;

export const state = {
  token: "",
  user: "",
};

export const getters = {
  signedIn: (state) => {
    return state.token && state.user ? true : false;
  },
};

export const mutations = {
  signInSuccess(state, userData) {
    state.token = userData.token;
    state.user = userData.user;
    router.push(router.currentRoute.value.query.redirect || { name: "Dashboard" });
  },

  signOutSuccess(state) {
    state.token = "";
    state.user = "";
  },

  setUser(state, domain) {
    state.user = StorageService.getUser(domain);
  },

  setToken(state, domain) {
    state.token = StorageService.getToken(domain);
  },
};

export const actions = {
  setUser({ commit }, domain) {
    commit("setUser", domain);
  },

  setToken({ commit }, domain) {
    commit("setToken", domain);
  },

  async signIn({ commit, dispatch }, credentials) {
    try {
      const response = await AuthService.signIn(credentials);
      commit("signInSuccess", response.data);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async signOut({ commit }) {
    AuthService.signOut();
    commit("signOutSuccess");
  },
};
