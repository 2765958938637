import axios from "axios";
import StorageService from "./storage.service";
import store from "../store";
import router from "../router";

class ApiError extends Error {
  constructor(errorCode, errorMessage) {
    super(errorMessage);
    this.name = this.constructor.name;
    this.errorMessage = errorMessage;
    this.errorCode = errorCode;
  }
}

const ApiService = {
  _401Interceptor: null,
  baseAddress: "https://prod-hesapal-admin-api-sjl2lbetbq-ew.a.run.app/",

  setApiAdress(address) {
    this.baseAddress = address;
  },

  init() {},

  getHeader() {
    return axios.defaults.headers.common["Authorization"];
  },

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${StorageService.getToken(store.state.domain)}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource, data) {
    return axios.delete(resource, { data: data });
  },

  customRequest(data) {
    return axios(data);
  },

  mount401Interceptor() {
    this._401Interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.request.status == 401) {
          store.dispatch("auth/signOut", false);
          router.push({
            name: "SignIn",
            query: { redirect: router.currentRoute.path },
          });
          throw error;
        }
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401Interceptor);
  },
};

export { ApiService, ApiError };
