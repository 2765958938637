import OrderService from "../../services/order.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async getOrder({ dispatch }, id) {
    try {
      const response = await OrderService.getOrder(id);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async deleteOrder({ dispatch }, id) {
    try {
      const response = await OrderService.deleteOrder(id);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Sipariş başarıyla silindi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async updateOrder({ dispatch }, payload) {
    try {
      const response = await OrderService.updateOrder(payload);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Sipariş başarıyla güncellendi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getOrdersByEmail({ dispatch }, email) {
    try {
      const response = await OrderService.getOrdersByEmail(email);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async findOrder({ dispatch }, usernameOrEmail) {
    try {
      const response = await OrderService.findOrder(usernameOrEmail);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async getAccountsBySoldProductId({ dispatch }, id) {
    try {
      const response = await OrderService.getAccountsBySoldProductId(id);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async renewCompensate({ dispatch }, id) {
    try {
      const response = await OrderService.renewCompensate(id);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Telafi başarıyla yenilendi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async refundProduct({ dispatch }, id) {
    try {
      const response = await OrderService.refundProduct(id);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Ürün başarıyla iade edildi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async refundOrder({ dispatch }, id) {
    try {
      const response = await OrderService.refundOrder(id);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Sipariş başarıyla iade edildi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async createOrder({ dispatch }, order) {
    try {
      const response = await OrderService.createOrder(order);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Sipariş başarıyla oluşturuldu.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async deliverOrder({ dispatch }, order) {
    try {
      const response = await OrderService.deliverOrder(order);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Sipariş başarıyla teslim edildi.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async checkAccounts({ dispatch }, id) {
    try {
      const response = await OrderService.checkAccounts(id);
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },

  async createInvoice({ dispatch }, invoice) {
    try {
      const response = await OrderService.createInvoice(invoice);
      dispatch(
        "addToast",
        {
          type: "success",
          message: "Fatura başarıyla oluşturuldu.",
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        "addToast",
        {
          type: "error",
          message: error.errorMessage,
        },
        { root: true }
      );
    }
  },
};
